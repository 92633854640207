import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
   getPhantomWallet,
   getSolflareWallet,
   getSolletWallet,
   getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
   ConnectionProvider,
   WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
   palette: {
      type: "dark",
   },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
   ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
   : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
   const endpoint = useMemo(() => clusterApiUrl(network), []);

   const wallets = useMemo(
      () => [
         getPhantomWallet(),
         getSolflareWallet(),
         getSolletWallet(),
         getMathWallet(),
      ],
      []
   );



   return (
      <div>
         <div className="page-wrapper">
            <div className="navbar wf-section">
               <div className="navbar-container">
                  <img src="/img/logotop.png" loading="lazy" width="60" id="w-node-_565cd0af-c86c-dd2a-14f2-65bbc7f7bcc9-c21dd95c" alt="" className="image-11" /><a id="w-node-b282fa53-c876-a363-d487-995c7a386309-c21dd95c" href="#" className="home w-inline-block"></a>
                  <div id="w-node-b282fa53-c876-a363-d487-995c7a38630c-c21dd95c" className="nav-menu">
                     <a href="https://discord.gg/yfnAt3dzkE" data-w-id="b282fa53-c876-a363-d487-995c7a38630d" target="_blank" className="nav-link-dope-edition right-32 w-inline-block">
                        <div className="nav-text-wrap">
                           <div className="nav-text">Discord works</div>
                           <div className="nav-text-hover">how it works</div>
                        </div>
                     </a>
                     <a href="#Roadmap" data-w-id="bbee343a-1ceb-423e-8eeb-60c1174b6fe9" className="nav-link-dope-edition right-32 w-inline-block">
                        <div className="nav-text-wrap">
                           <div className="nav-text" >Roadmap works</div>
                           <div className="nav-text-hover">how it works</div>
                        </div>
                     </a>
                     <a href="#Benefits" data-w-id="b282fa53-c876-a363-d487-995c7a386319" className="nav-link-dope-edition right-32 w-inline-block">
                        <div className="nav-text-wrap">
                           <div className="nav-text">Benefits</div>
                           <div className="nav-text-hover">pricing</div>
                        </div>
                     </a>
                     <a href="#Meet-The-Team" data-w-id="b282fa53-c876-a363-d487-995c7a38631f" className="nav-link-dope-edition right-32 w-inline-block">
                        <div className="nav-text-wrap">
                           <div className="nav-text">Team team</div>
                           <div className="nav-text-hover">the team</div>
                        </div>
                     </a>
                  </div>
                  <a id="w-node-b282fa53-c876-a363-d487-995c7a38632b-c21dd95c" data-w-id="b282fa53-c876-a363-d487-995c7a38632b" href="#" className="button secondary small nav-cta hide w-button">get in touch</a>
               </div>
            </div>
            <div id="mint" data-w-id="b282fa53-c876-a363-d487-995c7a38632d" className="section grain top-140 wf-section">
               <div className="container">
                  <div className="w-layout-grid hero-grid">
                     <div id="w-node-b282fa53-c876-a363-d487-995c7a386331-c21dd95c" className="hero-content-wrap design-to-webflow">
                        <ThemeProvider theme={theme}>
                           <ConnectionProvider endpoint={endpoint}>
                              <WalletProvider wallets={wallets} autoConnect>
                                 <WalletDialogProvider>
                                    <Minter
                                       candyMachineId={candyMachineId}
                                       connection={connection}
                                       startDate={startDateSeed}
                                       txTimeout={txTimeout}
                                       rpcHost={rpcHost}
                                    />
                                 </WalletDialogProvider>
                              </WalletProvider>
                           </ConnectionProvider>
                        </ThemeProvider>
                     </div>
                     <img src="/img/maingif2.gif" loading="lazy" alt="" className="image-10" />
                  </div>
               </div>
               <div className="circle-blur-top-left-2 safari"></div>
            </div>
            <div id="Roadmap" className="section black wf-section">
               <div className="container">
                  <div className="center-div max-720w margin-xl">
                     <h2 className="heading-2-small text-white">The <span className="text-span">Roadmap</span></h2>
                  </div>
                  <div className="w-layout-grid container small flex">
                     <div data-w-id="30c8d968-55fe-8251-987b-0e377becbfc3" className="timeline-wrapper">
                        <div className="grey-line">
                           <div className="white-line"></div>
                        </div>
                     </div>
                     <div className="process-wrapper">
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-1-info-wrapper">
                              <img src="/img/logoroad.png" loading="lazy" width="167" alt="" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">WELCOME TO THE</span> <span className="text-span-3">MY MASONS CLUB.</span></h4>
                                 <p className="paragraph-m text-white margin-m">Join our discord and take a seat.</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-2-info-wrapper">
                              <img src="/img/7.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">Collaborating with poor people welfare organizations</span></h4>
                                 <p className="paragraph-m text-white margin-m">10% of mint will be donated to Per Sempre Association, which helps people that are in a tough situation, mostly the refugees from Ukraine.<br></br>
                                    Continued collaborations w/ royalties and events hosted for these organizations.</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-3-info-wrapper">
                              <img src="/img/9.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">MERCH: Genesis Collection 0.0</span></h4>
                                 <p className="paragraph-m text-white margin-m">The first ever release of our merch that will be given to holders. Free. The only opportunity to grab this drop.</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-5-info-wrapper">
                              <img src="/img/30.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">EVENT: Opening Event in Bucharest, Romania</span></h4>
                                 <p className="paragraph-m text-white margin-m">The first opening event for all holders in Bucharest, Romania. Free entry. Network and have fun</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-5-info-wrapper">
                              <img src="/img/48.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">Club Competitions</span></h4>
                                 <p className="paragraph-m text-white margin-m">Competitions ranging from games, sports, poker, musical talent, and more. Prizes for winners.</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-5-info-wrapper">
                              <img src="/img/67.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">Metaverse</span></h4>
                                 <p className="paragraph-m text-white margin-m">Community Driven decision making using Snapshot<br />Become the Freemasons of the Metaverse</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-5-info-wrapper">
                              <img src="/img/7.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">The Treat Box</span></h4>
                                 <p className="paragraph-m text-white margin-m">Prizes range from clothes, concert tickets, football tickets, flights or maybe… a brand new car.</p>
                              </div>
                           </div>
                        </div>
                        <div className="step-wrapper margin-l">
                           <div className="step">
                              <div className="black-line"></div>
                           </div>
                           <div className="step-5-info-wrapper">
                              <img src="/img/9.png" loading="lazy" width="198" sizes="(max-width: 479px) 100vw, 197.98828125px" alt="" className="roadmap-image" />
                              <div className="step-info">
                                 <h4 className="step-heading margin-xs"><span className="text-span-4">GEN 2: New symbols</span></h4>
                                 <p className="paragraph-m text-white margin-m">Predicted rollout to be Q3-Q4 of 2022. Priority is to build up MyMasons first before rolling out the new symbols.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="container">
                  <div className="w-layout-grid container small flex">
                     <div data-w-id="abfd9031-e470-2fca-7df7-93f236137247" className="timeline-wrapper">
                        <div className="grey-line">
                           <div className="white-line"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="Artwork" data-w-id="b282fa53-c876-a363-d487-995c7a3863b2" className="section grain wf-section">
               <div className="container centred full-width">
                  <div className="center-div max-800w margin-xl">
                     <h2 className="heading-2-small">Featured <span className="text-span-5">Artwork</span></h2>
                     <p className="paragraph-5">MyMasons is a collection of 5,000 unique, digital Freemasons NFT collectibles that represent community through ownership. The MyMasons are stored on the Solana blockchain. Owning an Mason comes with exclusive utilities reserved only for group members. The MyMasons Club will be released on June 1, 2022. </p>
                  </div>
                  <div data-duration-in="500" data-duration-out="500" data-w-id="b282fa53-c876-a363-d487-995c7a3863bc" data-current="With Relume" data-easing="ease" className="w-tabs">
                     <div className="relume-comparison-tab-content w-tab-content">
                        <div data-w-tab="With Relume" className="tab-pane-with-relume w-tab-pane w--tab-active"><img src="/img/maingif.gif" loading="lazy" alt="" className="comparison-img---mobile" /><img src="/img/maingif.gif" loading="eager" width="727" alt="" className="comparison-img---desktop" /></div>
                        <div data-w-tab="Without Relume" className="tab-pane-without-relume w-tab-pane"><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f8c361dd989_Without%20Relume%20-%20Mobile.png" loading="eager" sizes="(max-width: 479px) 89vw, 100vw" className="comparison-img---desktop" /></div>
                     </div>
                  </div>
               </div>
               <div className="circle-blur-middle-left safari"></div>
            </div>
            <div id="Benefits" data-w-id="54b74ad2-7611-4542-f34d-7bd6c6f6ef00" className="section grain wf-section">
               <div className="container centred full-width">
                  <div className="center-div max-720w margin-xl">
                     <h2 className="heading-2-small">Exclusive Members Only <span className="text-span-6">Benefits</span><span className="with-underline long-underline"></span></h2>
                  </div>
                  <div className="grid-2-col margin-l">
                     <div className="profile-wrapper">
                        <div className="profile-bg-colour">
                           <img src="/img/7.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 991px) 75vw, (max-width: 1279px) 21vw, 22vw" alt="Portrait photo of co-founder Dan" className="profile-photo dan" />
                           <div className="random-facts dan">
                              <img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f71d71dd996_Dan%20Fact%201.png" loading="eager" alt="Image and text that reads &quot;started his first business at age 15&quot;" className="random-fact dan-fact-1" /><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f39f21dd99d_Dan%20Fact%202.png" loading="eager" alt="Image and text that reads &quot;can play beethoven blindfolded&quot;" className="random-fact dan-fact-2" />
                              <h6 className="personality-description">"driven &amp; friendly"</h6>
                           </div>
                        </div>
                        <div className="wrapper _16-margins">
                           <p className="paragraph-m text-centred">Good Karma</p>
                           <div className="profile-button-wrap">
                              <p className="paragraph">Donate 10 percent of mint sales to Per Sempre Association, based in Bucharest, Romania.</p>
                           </div>
                        </div>
                     </div>
                     <div className="profile-wrapper">
                        <div className="profile-bg-colour">
                           <img src="/img/9.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 991px) 75vw, (max-width: 1279px) 21vw, 22vw" alt="Portrait photo of co-founder Dan" className="profile-photo dan" />
                           <div className="random-facts dan">
                              <img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f71d71dd996_Dan%20Fact%201.png" loading="eager" alt="Image and text that reads &quot;started his first business at age 15&quot;" className="random-fact dan-fact-1" /><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f39f21dd99d_Dan%20Fact%202.png" loading="eager" alt="Image and text that reads &quot;can play beethoven blindfolded&quot;" className="random-fact dan-fact-2" />
                              <h6 className="personality-description">"driven &amp; friendly"</h6>
                           </div>
                        </div>
                        <div className="wrapper _16-margins">
                           <p className="paragraph-m text-centred">Commercial Rights</p>
                           <div className="profile-button-wrap">
                              <p className="paragraph-3">Get full ownership of your MyMasons NFT, including both creative and commercial rights.</p>
                           </div>
                        </div>
                     </div>
                     <div className="profile-wrapper">
                        <div className="profile-bg-colour">
                           <img src="/img/30.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 63vw, (max-width: 1279px) 21vw, 22vw" alt="Portrait photo of co-founder Adam" className="profile-photo adam" />
                           <div className="random-facts adam">
                              <img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f8bc71dd99a_Adam%20Fact%201.png" loading="eager" width="152" alt="Image and text that reads &quot;can moonwalk like MJ&quot;" className="random-fact adam-fact-1" /><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2fee921dd98f_Adam%20Fact%202.png" loading="eager" alt="Image and text that reads &quot;taught himself photoshop at age 12&quot;" className="random-fact adam-fact-2" />
                              <h6 className="personality-description">"passionate &amp; creative"</h6>
                           </div>
                        </div>
                        <div className="wrapper _16-margins">
                           <p className="paragraph-m text-centred">Events & Networking</p>
                        </div>
                     </div>
                     <div className="profile-wrapper lastcol">
                        <div className="profile-bg-colour">
                           <img src="/img/48.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 991px) 75vw, (max-width: 1279px) 21vw, 22vw" alt="Portrait photo of co-founder Dan" className="profile-photo dan" />
                           <div className="random-facts dan">
                              <img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f71d71dd996_Dan%20Fact%201.png" loading="eager" alt="Image and text that reads &quot;started his first business at age 15&quot;" className="random-fact dan-fact-1" /><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f39f21dd99d_Dan%20Fact%202.png" loading="eager" alt="Image and text that reads &quot;can play beethoven blindfolded&quot;" className="random-fact dan-fact-2" />
                              <h6 className="personality-description">"driven &amp; friendly"</h6>
                           </div>
                        </div>
                        <div className="wrapper _16-margins">
                           <p className="paragraph-m text-centred">Exclusive Merchandise</p>
                        </div>
                     </div>

                  </div>
               </div>
               <div className="container centred full-width"></div>
               <div className="circle-blur-bottom-left safari"></div>
            </div>
            <div id="Meet-The-Team" data-w-id="ad152906-edc4-2960-423c-ced224eeb050" className="section grain wf-section">
               <div className="container centred full-width">
                  <div className="center-div max-720w margin-xl">
                     <h2 className="heading-2-small">Meet The <span className="text-span-6">Team</span><span className="with-underline long-underline"></span></h2>
                  </div>
                  <div className="grid-2-col margin-l team-grid">
                     <div className="profile-wrapper team-photo">
                        <div className="profile-bg-colour">
                           <img src="/img/65.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, 178.2421875px" alt="Portrait photo of co-founder Adam" className="profile-photo adam" />
                           <div className="random-facts adam">
                              <img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f8bc71dd99a_Adam%20Fact%201.png" loading="eager" width="152" alt="Image and text that reads &quot;can moonwalk like MJ&quot;" className="random-fact adam-fact-1" /><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2fee921dd98f_Adam%20Fact%202.png" loading="eager" alt="Image and text that reads &quot;taught himself photoshop at age 12&quot;" className="random-fact adam-fact-2" />
                              <h6 className="personality-description">"passionate &amp; creative"</h6>
                           </div>
                        </div>
                        <div className="wrapper _16-margins">
                           <p className="paragraph-m text-centred founder-name">@alexpos </p>
                           <p className="paragraph-m text-centred founder-title">Co-Founder</p>
                        </div>
                     </div>
                     <div className="profile-wrapper team-photo">
                        <div className="profile-bg-colour">
                           <img src="/img/67.png" loading="eager" sizes="(max-width: 479px) 100vw, (max-width: 767px) 40vw, 178.2421875px" alt="Portrait photo of co-founder Dan" className="profile-photo dan" />
                           <div className="random-facts dan">
                              <img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f71d71dd996_Dan%20Fact%201.png" loading="eager" alt="Image and text that reads &quot;started his first business at age 15&quot;" className="random-fact dan-fact-1" /><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61be5f7dbb1f2f39f21dd99d_Dan%20Fact%202.png" loading="eager" alt="Image and text that reads &quot;can play beethoven blindfolded&quot;" className="random-fact dan-fact-2" />
                              <h6 className="personality-description">"driven &amp; friendly"</h6>
                           </div>
                        </div>
                        <div className="wrapper _16-margins">
                           <p className="paragraph-m text-centred founder-name">@razvang </p>
                           <p className="paragraph-m text-centred founder-title">Co-Founder</p>
                        </div>
                     </div>


                  </div>
               </div>

               <div className="container centred full-width"></div>
               <div className="circle-blur-bottom-left safari"></div>
            </div>
            <div id="Get-Started" data-w-id="b282fa53-c876-a363-d487-995c7a386568" className="section black wf-section">
               <div className="container">
                  <div className="wrapper flex-vertical margin-l">
                     <h2 className="text-white text-centred margin-m">Join The <span className="text-span-7">Discord</span></h2>
                     <div className="center-div max-560w"><a href="https://discord.gg/yfnAt3dzkE" target="_blank" className="button w-button">Join&nbsp;Now</a></div>
                  </div>
               </div>
            </div>
            <div className="section angry-pitbull-footer wf-section">
               <div className="container static">
                  <div className="footer-wrapper margin-xl">
                     <div className="wrapper footer-logo"><a href="#" className="home w-inline-block"><img src="/img/logotop.png" loading="lazy" width="59" alt="" /></a></div>
                  </div>
                  <div className="footer-credits">
                     <div className="columns w-row">
                        <div className="column w-col w-col-6">
                           <p className="paragraph-s text-light-grey">© 2022, MY MASONS CLUB. All Rights Reserved.</p>
                        </div>
                        <div className="w-col w-col-6">
                           <div className="div-block-20"><a href="https://www.instagram.com/" target="_blank" className="social-button w-inline-block"><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61bf8046b2f1c617c3b14cc6_icons8-instagram-logo-48%20(1).png" loading="lazy" width="24" alt="" className="social" /></a><a href="https://discord.gg/yfnAt3dzkE" target="_blank" className="social-button w-inline-block"><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61bf8046b2f1c64a8db14cc8_discord.png" loading="lazy" width="24" alt="" className="social" /></a><a href="https://twitter.com/mymasonsnft" target="_blank" className="w-inline-block"><img src="https://uploads-ssl.webflow.com/61be5f7dbb1f2f2c241dd958/61bf8046b2f1c64b32b14cc4_icons8-twitter-squared-48%20(1).png" loading="lazy" width="24" alt="" className="style-guide---icons" /></a></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default App;
